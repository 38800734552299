"use client";

import { captureException } from "@sentry/nextjs";
import { useEffect } from "react";

// TODO: noindex, etc.
// TODO: fix headings, etc

export default function Error({ error }: { error: Error & { digest?: string } }) {
	useEffect(() => {
		captureException(error);
	}, [error]);

	return (
		<div>
			<h2>Something went wrong! (route)</h2>
		</div>
	);
}
